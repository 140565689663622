import {reactive, toRefs, onBeforeMount, defineComponent, getCurrentInstance} from 'vue';
import language from './onlineuserLanguage'
export default defineComponent({
    name: "onlineuser",
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;

        let dataObj=reactive({
            pageListRef:null as any,
            pageList: {
                queryParam: {

                },
                modelMethod: utils.Api.buildUrl("/onlineUser/pageData")
            }
        })
        onBeforeMount(()=>{
            proxy.addLangProps(language);
        })
        const forceOutHandler=async()=>{
            let selectRows = dataObj.pageListRef.getTbInst().getSelection();
            if (selectRows.length == 0) {
                utils.Tools.info({message: proxy.$t('onlineuser.selTip')});
                return;
            }
            utils.Tools.configBox({
                message:'确定执行该操作吗?',
                sureFn:async ()=> {
                    let clientIds ='';
                    selectRows.forEach((item:any)=> {
                        clientIds = clientIds + item.F_ID + ",";
                    });

                    clientIds = clientIds.trim().substr(0, clientIds.trim().length - 1);//去掉最后的逗号
                    const loading = proxy.$loading({lock: true,text: proxy.$t('loadMsg'),spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'});
                    let res = await utils.Api.onlineuserCustom({clientIds: clientIds});
                    if(res.result){

                        setTimeout(()=>{
                            dataObj.pageListRef.queryHandler(true);
                            utils.Tools.success({message: res.msg});
                            loading.close();
                        },2000)
                    }
                }
            });
        }

        return{
            ...toRefs(dataObj),forceOutHandler
        }
    }
});