export default {
    zh:{
        onlineuser:{
            selTip:'请选择要强制退出的用户行',
            queryParam:{
                code:'编码',
                code_placeholder:'请输入编码模糊匹配查询...',
                ip:'ip地址',
                ip_placeholder:'请输入ip地址模糊匹配查询...',
            },
            columns:{
                F_USER_CODE:'编码',
                F_USER_NAME:'名称',
                F_CORP_NAME:'单位',
                F_DEPT_NAME:'部门',
                F_PERSON_NAME:'人员',
                F_LOGIN_IP:'登录IP',
                F_LOGIN_TIME:'登录时间',
                F_LOGIN_PLACE:'登录地点',
                F_OPERATOR:'操作系统',
                F_BROWER:'浏览器',
            },
        }
    },
    en:{
        onlineuser:{
            selTip:'Please select the user line to force exit',
            queryParam:{
                code:'code',
                code_placeholder:'Please enter a code fuzzy matching query...',
                ip:'ip',
                ip_placeholder:'Please enter a ip fuzzy matching query...',
            },
            columns:{
                F_USER_CODE:'code',
                F_USER_NAME:'name',
                F_CORP_NAME:'organization',
                F_DEPT_NAME:'department',
                F_PERSON_NAME:'person',
                F_LOGIN_IP:'login IP',
                F_LOGIN_TIME:'login time',
                F_LOGIN_PLACE:'login place',
                F_OPERATOR:'system',
                F_BROWER:'browser',
            },
        }
    }
}